<template>
    <b-container fluid>
        <div class="card card-primary card-outline">
            <div class="card-header">
                <div class="card-title">Healthy Claims</div>

                <div class="card-tools">
                    <button type="button" class="mr-1 btn btn-default" @click="loadClaims">
                        <i class="fas fa-sync"></i>
                    </button>
                    <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('healthy-claims.create')">
                        <i class="fas fa-plus-circle"></i> Neuer Claim
                    </button>
                    <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                </div>
            </div>

            <div class="card-body">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Claim</th>
                            <th>Aktion</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(claim,index) in claims" :key="claim.id">
                            <td>{{ index +1 }}</td>
                            <td>{{ claim.name }}</td>
                            <td>
                                <b-button size="sm" @click="editModal(claim)" class="mr-1" variant="warning" v-if="$auth.check('healthy-claims.edit')"><i class="fas fa-edit"></i></b-button>
                                <b-button size="sm" @click="deleteClaim(claim.id)" variant="danger" v-if="$auth.check('healthy-claims.destroy')"><i class="fas fa-trash"></i></b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <b-modal id="claimModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="sm" @ok="handleOk($event, form.id)">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="title" class="control-label">Claim*</label>
                        <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}">
                        <has-error :form="form" field="name"></has-error>
                    </div>
                </div>
            </div>
        </b-modal>



    </b-container>
</template>

<script>
import ClassicEditor from '../../assets/packages/ckeditor5/src/ckeditor';


export default {
    name: "HealthyClaims",
    title: "Healthy Claims",

    data(){
        return {
            claims: [],
            editMode: false,
            modalTitle: '',
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            form: new window.Form({
                id: "",
                name: '',
            }),
        }
    },

    methods:{
        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editMode == true)
            {
                //Edit User
                this.editClaim(id);
            }
            else
            {
                //Create User
                this.createClaim();
            }
        },

        createModal()
        {
            this.editMode = false;
            this.modalTitle = "Neuen Claim anlegen";
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("claimModal");
        },
        
        editModal(Claim)
        {
            this.editMode = true;
            this.modalTitle = "Claim editieren";
            this.form.clear();
            this.form.reset();
            this.form.fill(Claim);
            this.$bvModal.show("claimModal");
        },

        createClaim() {
            this.$Progress.start();
            this.form
                .post("/healthy-claims")
                .then(() => {
                    this.$bvModal.hide("claimModal");
                    this.$swal({
                        icon: "success",
                        title: "Claim wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadClaims();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        editClaim(id){
            this.$Progress.start();
            this.form
                .put("/healthy-claims/"+ id)
                .then(() => {
                    this.$bvModal.hide("claimModal");
                    this.$swal({
                        icon: "success",
                        title: "Job wurde editiert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    this.loadClaims();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        deleteClaim(id){
            this.$swal({
                title: "Möchtest du den Claim wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/healthy-claims/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Claim erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadClaims();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        loadClaims(){
            this.axios
                .get("/healthy-claims")
                .then((response) => {
                    this.claims = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },
    },

    created(){
        this.loadClaims();
    }

}
</script>

<style>

</style>